import { NavLink } from 'react-router-dom';

interface DocumentCardProps {
  title: string;
  subTitle: string;
  email: string;
  major: string;
  school: string;
  to: string;
  copyContent?: any;
  id?: string;
}

const DocumentCard: React.FC<DocumentCardProps> = ({
  title,
  subTitle,
  to,
  copyContent,
  email,
  school,
  major,
  id,
}) => {
  return (
    <div className='relative z-10 max-h-[266px] rounded-[20px] bg-white px-4 py-3 md:p-5 xl:p-6 2xl:p-7'>
      <div className='space-y-2'>
        <NavLink to={`/contest/${id}/pdf/${id || ''}`}>
          <h2 className='mr-16 text-base font-semibold md:text-xl lg:text-2xl'>{title}</h2>
        </NavLink>
        <p className='truncate text-sm text-[#696984] md:text-base'>{subTitle}</p>
      </div>
      <div className='mt-4 bg-[#9DCCFF] bg-opacity-20 p-2 md:mt-5 md:p-3 xl:mt-6 xl:p-4 2xl:mt-7 2xl:p-5'>
        <p className='max-h-[75px] overflow-hidden text-ellipsis text-sm text-[#696984] md:text-base'>
          Email: {email}
        </p>
        <p className='max-h-[75px] overflow-hidden text-ellipsis text-sm text-[#696984] md:text-base'>
          Major: {major}
        </p>
        <p className='max-h-[75px] overflow-hidden text-ellipsis text-sm text-[#696984] md:text-base'>
          School: {school}
        </p>
      </div>
    </div>
  );
};

export default DocumentCard;
