import { useEffect, useRef, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { ReactComponent as LargeLogoCTCT } from '../../assets/svgs/LargeLogoCTCT.svg';
import { useAppDispatch, useAppSelector, useThrottle } from '../../hooks';
import { logout } from '../../slices/actions/auth.action';
import { AuthAction } from '../../slices/auth';
import { RootState } from '../../store';
import Icon from '../Icon';

const LargeHeader = () => {
  const { pathname } = useLocation();
  const libraryRef = useRef<HTMLDivElement>(null);
  const roomRef = useRef<HTMLDivElement>(null);
  const aboutUsRef = useRef<HTMLDivElement>(null);
  const profileRef = useRef<HTMLDivElement>(null);

  const [isLibraryOpen, setIsLibraryOpen] = useState(
    pathname === '/library/material' || pathname === '/'
  );
  const [isRoomOpen, setIsRoomOpen] = useState(
    pathname === '/room/exercises' || pathname === '/room/tests'
  );
  const [isAboutUsOpen, setIsAboutUsOpen] = useState(
    pathname === '/about-us' ||
      pathname === '/about-us/activities' ||
      pathname === '/about-us/partners'
  );
  const [isProfileDrop, setIsProfileDrop] = useState(false);

  const { isAuthenticated } = useAppSelector((state: RootState) => state.auth);

  const user = useAppSelector((state: RootState) => state.user);

  const dispatch = useAppDispatch();

  useEffect(() => {
    document.addEventListener('click', (event) => {
      if (event.target instanceof HTMLElement) {
        if (libraryRef.current && !libraryRef.current.contains(event.target)) {
          setIsLibraryOpen(false);
        }
        if (roomRef.current && !roomRef.current.contains(event.target)) {
          setIsRoomOpen(false);
        }
        if (aboutUsRef.current && !aboutUsRef.current.contains(event.target)) {
          setIsAboutUsOpen(false);
        }
        if (profileRef.current && !profileRef.current.contains(event.target)) {
          setIsProfileDrop(false);
        }
      }
    });
  }, [libraryRef, roomRef, aboutUsRef, profileRef]);

  const onLibraryClick = () => {
    setIsLibraryOpen(!isLibraryOpen);
  };

  const onRoomClick = () => {
    setIsRoomOpen(!isRoomOpen);
  };

  const onAboutUsClick = () => {
    setIsAboutUsOpen(!isAboutUsOpen);
  };

  const onProfileClick = () => setIsProfileDrop(!isProfileDrop);

  const throttledLibraryClick = useThrottle(onLibraryClick);
  const throttledRoomClick = useThrottle(onRoomClick);
  const throttledAboutUsClick = useThrottle(onAboutUsClick);

  return (
    <div className='flex-column relative top-0 z-30 hidden w-full flex-wrap bg-white md:flex'>
      <div
        className='z-30 flex w-[100%] flex-row items-center justify-between
        bg-white px-[16px] py-[12px] 2xl:px-[32px] 2xl:py-[16px]'
      >
        <NavLink to='/' className='aspect-[107/60] h-[40px] w-auto 2xl:h-[48px]'>
          <LargeLogoCTCT className='aspect-[107/60] h-[40px] w-auto 2xl:h-[48px]' />
        </NavLink>
        <div className='relative flex flex-row gap-x-[52px]'>
          {!isAuthenticated && (
            <button
              type='submit'
              className='inset-y-5 right-5 w-[144px] cursor-pointer rounded-[8px] 
              bg-[#4285F4] text-base text-white 
              duration-300 ease-out hover:bg-[#2374FA]'
              onClick={() => dispatch(AuthAction.loginWithGoogle())}
            >
              Đăng nhập
            </button>
          )}
          {isAuthenticated && (
            <>
              <div ref={profileRef}>
                <button
                  className='flex flex-row items-center justify-center'
                  onClick={onProfileClick}
                >
                  <img
                    alt='profile_pic'
                    src={user?.picture || require('../../assets/images/AvatarPic.png')}
                    className='mr-[16px] h-[42px] w-[42px] rounded-[999px] border-2
                  border-[#49BBBD] bg-[#979797] 2xl:mr-[24px] 2xl:h-[50px] 2xl:w-[50px]'
                  />
                  <Icon.ChevronUp
                    fill={'#3b3b3b'}
                    fillOpacity={0.87}
                    className={`transform-all aspect-[10/7] h-auto w-[8px] duration-300 ${
                      isProfileDrop ? 'rotate-0' : 'rotate-180'
                    }`}
                  />
                </button>
              </div>
              <nav
                className='set-11 absolute right-0 top-[136%] z-10 mt-1 flex w-[200px] flex-col 
            items-center justify-center rounded-lg bg-[#FBFCFF]
            transition-all duration-300'
                style={{
                  transform: isProfileDrop ? 'translateY(0%)' : 'translateY(10%)',
                  maxHeight: isProfileDrop ? '1000px' : '0px',
                  opacity: isProfileDrop ? 1 : 0,
                  overflow: 'hidden',
                  boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.1)',
                }}
              >
                <button
                  className='bg-inherit px-[16px] py-[8px] 2xl:px-[32px] 2xl:py-[12px]'
                  onClick={() => dispatch(logout())}
                >
                  <p
                    className='whitespace-nowrap bg-inherit px-2 py-1 text-[14px] font-bold text-[#B42926] transition-all duration-300 ease-linear 2xl:px-3
                    2xl:py-2 2xl:text-[18px]'
                  >
                    Đăng xuất
                  </p>
                </button>
              </nav>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default LargeHeader;
