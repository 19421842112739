import { lazy } from 'react';

const HomePage = lazy(() => import('../pages/Home'));
const ExamArchivePage = lazy(() => import('../pages/Library/ExamArchivePage'));
const ExamArchiveDetail = lazy(() => import('../pages/Library/ExamArchiveDetail'));
const NotFoundPage = lazy(() => import('../pages/NotFound'));

export interface Route {
  title: string;
  path: string;
  component: React.FC;
  isProtected: boolean;
}

const routes = [
  {
    title: 'Đội thi',
    path: '/contest/:subjectId?',
    component: ExamArchivePage,
    isProtected: true,
  },
  {
    title: 'Bài thi',
    path: '/contest/:subjectId/pdf/:pdfId?',
    component: ExamArchiveDetail,
    isProtected: true,
  },
  { title: 'Đăng nhập', path: '/login', component: HomePage, isProtected: true },
  { title: 'Đăng nhập', path: '/', component: HomePage, isProtected: false },
  { title: 'Không tìm thấy', path: '*', component: NotFoundPage, isProtected: false },
];

export default routes;
