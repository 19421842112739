import { LottieOptions, useLottie } from 'lottie-react';
import { CSSProperties, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { ReactComponent as MediumLogoCTCT } from '../../assets/svgs/LargeLogoCTCT.svg';
import { useAppDispatch, useAppSelector, useDebounce, useThrottle } from '../../hooks';
import { logout } from '../../slices/actions/auth.action';
import { AuthAction } from '../../slices/auth';
import { RootState } from '../../store';
import Icon from '../Icon';

const MediumHeader = () => {
  const style: CSSProperties = {
    height: '32px',
    width: '32px',
  };
  const options: LottieOptions<'svg'> = {
    animationData: require('../../assets/animations/Hamburger.json'),
    loop: false,
    autoplay: false,
  };

  const { View, playSegments } = useLottie(options, style);
  const { pathname } = useLocation();

  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const [isLibraryOpen, setIsLibraryOpen] = useState(
    pathname === '/library/material' || pathname === '/'
  );
  const [isRoomOpen, setIsRoomOpen] = useState(
    pathname === '/room/exercises' || pathname === '/room/tests'
  );
  const [isAboutUsOpen, setIsAboutUsOpen] = useState(
    pathname === '/about-us' ||
      pathname === '/about-us/activities' ||
      pathname === '/about-us/partners'
  );

  const { isAuthenticated } = useAppSelector((state: RootState) => state.auth);

  const dispatch = useAppDispatch();

  const onClick = () => {
    isOverlayOpen ? playSegments([60, 30], true) : playSegments([30, 60], true);
    setIsOverlayOpen(!isOverlayOpen);
    setIsLibraryOpen(false);
    setIsRoomOpen(false);
    setIsAboutUsOpen(false);
  };

  const onLibraryClick = () => {
    setIsLibraryOpen(!isLibraryOpen);
    setIsRoomOpen(false);
    setIsAboutUsOpen(false);
  };

  const onRoomClick = () => {
    setIsLibraryOpen(false);
    setIsRoomOpen(!isRoomOpen);
    setIsAboutUsOpen(false);
  };

  const onAboutUsClick = () => {
    setIsAboutUsOpen(!isAboutUsOpen);
  };

  const onLogout = () => {
    // TODO
    dispatch(logout());
    setIsOverlayOpen(false);
    setIsLibraryOpen(false);
    setIsRoomOpen(false);
    setIsAboutUsOpen(false);
    playSegments([60, 30], true);
  };

  const throttledOnClick = useThrottle(onClick);
  const throttledLibraryClick = useThrottle(onLibraryClick);
  const throttledRoomClick = useThrottle(onRoomClick);
  const throttledAboutUsClick = useThrottle(onAboutUsClick);
  const debouncedLogout = useDebounce(onLogout);

  return (
    <>
      <div className='relative top-0 z-50 w-full'>
        <div
          className='relative z-40 flex h-[72px] w-[100%] flex-row items-center
          justify-between bg-[#f5f7fc]
          px-[20px] py-[16px] md:hidden'
          style={{ boxShadow: isOverlayOpen ? '0px 0px 10px 0px rgba(0, 0, 0, 0.1)' : 'none' }}
        >
          <NavLink to='/' className='aspect-[107/60] h-[40px] w-auto xl:h-[48px]'>
            <MediumLogoCTCT />
          </NavLink>
          <button type='button' onClick={throttledOnClick}>
            {View}
          </button>
        </div>
        <div
          className='absolute z-30 flex w-[100%] flex-col items-center justify-start
          gap-y-[24px] overflow-scroll overscroll-none whitespace-nowrap bg-white px-[20px]
          py-[16px] transition-all duration-[900ms] ease-in-out
          md:hidden'
          style={{
            transform: isOverlayOpen ? 'translateY(0%)' : 'translateY(calc(-100vh - 72px))',
            height: 'calc(100vh)',
          }}
        >
          <nav className='flex w-[100%] flex-col items-center gap-y-[12px]'>
            {!isAuthenticated && (
              <button
                type='submit'
                className='inset-y-5 right-5 w-[144px] cursor-pointer rounded-[8px] 
              bg-[#4285F4] text-base text-white 
              duration-300 ease-out hover:bg-[#2374FA]'
                onClick={() => dispatch(AuthAction.loginWithGoogle())}
              >
                Đăng nhập
              </button>
            )}
            {isAuthenticated && (
              <>
                <button
                  className='z-20 flex w-[100%] flex-row
              items-center justify-between rounded-[12px] bg-white px-[20px] py-[16px]'
                  onClick={debouncedLogout}
                >
                  <div
                    className='flex flex-row items-center justify-start gap-x-[16px]
              transition-opacity duration-[800ms] ease-in-out'
                  >
                    <Icon.Logout fill={'#696969'} />
                    <p style={{ color: '#696969' }}>Đăng xuất</p>
                  </div>
                </button>
              </>
            )}
          </nav>
        </div>
      </div>
    </>
  );
};

export default MediumHeader;
